import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserState } from 'core/providers/user-provider';
import SharedSpinner from 'shared/spinner/spinner';
import AdminRouting from 'modules/admin/admin-routing';
import AdminGuard from './admin-guard';
import AuthGuard from './auth-guard';
import PermissionGuard from './permission-guard';
import { Permission } from 'core/constants/permission';
import OrganisationSettingsRouting from 'modules/organisation-settings/organisation-settings-routing';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import AuthRouting from 'modules/auth/auth-routing';
import NoPermissions from 'shared/layout/no-permissions';
import PatientsRouting from 'modules/patients/patients-routing';
import { useLoadScript } from '@react-google-maps/api';
import { GoogleMapsLibraries } from 'core/constants/google-maps-libraries';
import AppointmentsCalendarRouting from 'modules/appointments-calendar/appointments-calendar-routing';
import HearingTestRouting from 'modules/hearing-test/hearing-test-routing';
import StockManagementRouting from 'modules/stock-management/stock-management-routing';
import OrdersRouting from 'modules/orders/orders-routing';
import ReportingRouting from 'modules/reporting/reporting-routing';
import MarketingRouting from 'modules/marketing/marketing-routing';
import FinanceRouting from 'modules/finance/finance-routing';
import PublicFormsRouting from 'modules/public-forms/public-forms-routing';
import Error from 'assets/images/error.svg';
import BookingWidget from 'modules/booking-widget/booking-widget';
import AlertsRouting from 'modules/alerts/alerts-routing';

const AppRouting = () => {
  const { authChecked } = useUserState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: GoogleMapsLibraries,
  });

  if (!authChecked || !isLoaded) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <SharedSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<AuthGuard requiresAuth={false} />}>
        <Route path='auth/*' element={<AuthRouting />} />
        <Route path='forms/*' element={<PublicFormsRouting />} />
        <Route path='booking-widget' element={<BookingWidget />} />
        <Route
          path='404'
          element={
            <div className='h-screen w-full flex flex-col items-center justify-center'>
              <img src={Error} alt='error404' className='max-h-[200px] mb-12' />
              <p className='header-xl'>404</p>
              <p className='text-gray-400'>Page not found</p>
            </div>
          }
        />
      </Route>
      <Route element={<AuthGuard requiresAuth={true} />}>
        <Route element={<AdminGuard requiresAdmin={true} />}>
          <Route path='admin/*' element={<AdminRouting />} />
        </Route>

        <Route element={<AdminGuard requiresAdmin={false} />}>
          <Route path='no-permissions' element={<NoPermissions />} />

          <Route
            element={
              <PermissionGuard
                requiredPermissions={[[Permission.APPOINTMENTS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              />
            }
          >
            <Route path='calendar/*' element={<AppointmentsCalendarRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='patients/*' element={<PatientsRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.STOCK_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='stock-management/*' element={<StockManagementRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard
                requiredPermissions={[
                  [Permission.PATIENT_HEARING_TESTS_CREATE],
                  [Permission.PATIENT_HEARING_TESTS_UPDATE],
                  [Permission.ORGANISATION_OWNER],
                ]}
              />
            }
          >
            <Route path='hearing-test/*' element={<HearingTestRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.ORDERS_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='orders/*' element={<OrdersRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.REPORTS_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='reporting/*' element={<ReportingRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard requiredPermissions={[[Permission.MARKETING_BROWSE], [Permission.ORGANISATION_OWNER]]} />
            }
          >
            <Route path='marketing/*' element={<MarketingRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard
                requiredPermissions={[[Permission.TRANSACTIONS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              />
            }
          >
            <Route path='finance/*' element={<FinanceRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard
                requiredPermissions={[[Permission.PATIENT_ALERTS_BROWSE], [Permission.ORGANISATION_OWNER]]}
              />
            }
          >
            <Route path='alerts/*' element={<AlertsRouting />} />
          </Route>
          <Route
            element={
              <PermissionGuard
                requiredPermissions={[
                  ...OrganisationSettingsNavbarOptions.flatMap((option) => option.requiredPermissions),
                  [Permission.ORGANISATION_OWNER],
                ]}
              />
            }
          >
            <Route path='organisation-settings/*' element={<OrganisationSettingsRouting />} />
          </Route>
        </Route>
      </Route>
      <Route path='' element={<Navigate replace to={'calendar'} />} />
      <Route path='*' element={<Navigate replace to={'calendar'} />} />
    </Routes>
  );
};

export default AppRouting;
