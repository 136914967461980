import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { ReactElement, useState } from 'react';
import CalendarDaily from './calendar-daily/calendar-daily';
import dayjs, { Dayjs } from 'dayjs';
import { InputNumber } from 'antd';
import CalendarWeekly from './calendar-weekly/calendar-weekly';
import SharedCalendarHeader from './calendar-header';
import ProgressBar from 'shared/progress-bar/progress-bar';
import { CalendarMode } from 'core/constants/calendar-mode';
import { CalendarTimeframe } from 'core/constants/calendar-timeframe';
import CalendarSettingsDrawer from './calendar-settings-drawer';
import { useSelector } from 'react-redux';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import _ from 'lodash';
import { useUserState } from 'core/providers/user-provider';
import { Timestamp } from 'firebase/firestore';
import { getTimestampFromDateAndTimeString } from 'shared/helpers/appointment-helpers';
import { RepeatFrequency } from 'core/constants/repeat-frequency';

export interface ISharedCalendarNewAppointment {
  start: string;
  end: string;
}

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  people: IDomainCalendarResource[];
  highlightedPerson?: string;
  highlightedClinic?: string;
  currentDate: Dayjs;
  changeDate: (newDate: string) => void;
  minDate?: Dayjs;
  extra?: ReactElement;
  loading?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  startHour: number;
  showAppointmentMenu?: boolean;
  calendarWrapperRef: React.RefObject<HTMLDivElement>;
  filters: { [key: string]: string[] };
}

const SharedCalendar = ({
  timeSlots,
  appointments,
  unavailability,
  people,
  highlightedPerson,
  highlightedClinic,
  currentDate,
  changeDate,
  minDate,
  extra,
  loading,
  newAppointment,
  startHour,
  showAppointmentMenu,
  calendarWrapperRef,
  filters,
}: ISharedCalendar) => {
  const [weeklyZoom, setWeeklyZoom] = useState(2);
  const headerHeight = 56;
  const [mode, setMode] = useState<CalendarMode>(CalendarMode.PEOPLE);
  const [timeframe, setTimeframe] = useState<CalendarTimeframe>(CalendarTimeframe.DAY);
  const weekly = timeframe === CalendarTimeframe.WEEK;
  const clinicState = useSelector(OrganisationSettingsSlice.selectClinics);
  const clinics = clinicState?.data ?? [];
  const filteredPeople =
    mode === CalendarMode.PEOPLE
      ? people.filter((person) => !filters.assignee || filters.assignee.includes(person.uid))
      : people;
  const filteredClinics =
    mode === CalendarMode.CLINICS
      ? clinics.filter((clinic) => !filters.clinic || filters.clinic.includes(clinic.uid))
      : clinics;
  const { organisationData } = useUserState();

  const handleToggleChange = (key: string, value: string) => {
    if (key === 'mode') {
      setMode(value as CalendarMode);
    } else if (key === 'timeframe') {
      setTimeframe(value as CalendarTimeframe);
    }
  };

  const processUnavailability = (unavailability: IHolidayAndUnavailabilityDao[]) => {
    return unavailability.flatMap((item) => {
      const startOfWeek = dayjs(currentDate).startOf('week').startOf('day');
      const unavailabilityStart = dayjs(item.startDateTime.toDate());
      const endOfWeek = dayjs(currentDate).endOf('week').endOf('day');
      const unavailabilityEnd = dayjs(item.endDateTime.toDate());
      const start = unavailabilityStart.isBefore(startOfWeek) ? startOfWeek : unavailabilityStart;
      const end = unavailabilityEnd.isAfter(endOfWeek) ? endOfWeek : unavailabilityEnd;
      const diff = end.diff(start, 'days') + 1;
      const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
      const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());

      if (diff <= 1) {
        return [item];
      } else {
        const datesToMap = _.range(diff).map((i) => start.add(i, 'day'));

        if (!item.repeat.isRepeating) {
          const events = datesToMap.map((date) => {
            const isStart = date.isSame(unavailabilityStart, 'day');
            if (isStart) {
              return {
                ...item,
                endDateTime: getTimestampFromDateAndTimeString(date, calendarEnd.format('HH:mm')),
              };
            }

            const isEnd = date.isSame(unavailabilityEnd, 'day');
            if (isEnd) {
              return {
                ...item,
                startDateTime: getTimestampFromDateAndTimeString(date, calendarStart.format('HH:mm')),
              };
            }

            return {
              ...item,
              startDateTime: getTimestampFromDateAndTimeString(date, calendarStart.format('HH:mm')),
              endDateTime: getTimestampFromDateAndTimeString(date, calendarEnd.format('HH:mm')),
            };
          });

          return events;
        } else {
          if (item.repeat.frequency === RepeatFrequency.DAILY) {
            const events = datesToMap.map((date) => {
              return {
                ...item,
                startDateTime: getTimestampFromDateAndTimeString(
                  date,
                  dayjs(item.startDateTime.toDate()).format('HH:mm')
                ),
                endDateTime: getTimestampFromDateAndTimeString(date, dayjs(item.endDateTime.toDate()).format('HH:mm')),
              };
            });

            return events;
          }

          const weekDiff = startOfWeek.diff(unavailabilityStart.startOf('week'), 'weeks');
          return [
            {
              ...item,
              startDateTime: Timestamp.fromDate(unavailabilityStart.add(weekDiff, 'weeks').toDate()),
              endDateTime: Timestamp.fromDate(unavailabilityEnd.add(weekDiff, 'weeks').toDate()),
            },
          ];
        }
      }
    });
  };

  return (
    <div className='flex flex-col grow overflow-hidden'>
      <SharedCalendarHeader
        currentDate={currentDate}
        changeDate={changeDate}
        headerHeight={headerHeight}
        extra={
          <>
            <CalendarSettingsDrawer handleToggleChange={handleToggleChange} mode={mode} timeframe={timeframe} />
            {extra}
            {weekly && (
              <InputNumber
                defaultValue={200}
                min={0}
                max={200}
                formatter={(value) => `${value}%`}
                onChange={(value) => (value ? setWeeklyZoom(value / 100) : null)}
              />
            )}
          </>
        }
        minDate={minDate}
        weekly={weekly}
      />
      {loading && <ProgressBar />}
      {timeframe === CalendarTimeframe.DAY && (
        <CalendarDaily
          {...{
            timeSlots,
            appointments,
            unavailability: mode === CalendarMode.CLINICS ? [] : processUnavailability(unavailability),
            people: filteredPeople,
            clinics: filteredClinics,
            highlightedPerson,
            highlightedClinic,
            currentDate,
            loading,
            newAppointment,
            startHour,
            showAppointmentMenu,
            calendarWrapperRef,
            mode,
          }}
        />
      )}

      {timeframe === CalendarTimeframe.WEEK && (
        <CalendarWeekly
          {...{
            timeSlots,
            appointments,
            unavailability: mode === CalendarMode.CLINICS ? [] : processUnavailability(unavailability),
            people: filteredPeople,
            clinics: filteredClinics,
            highlightedPerson,
            highlightedClinic,
            currentDate,
            loading,
            newAppointment,
            startHour,
            showAppointmentMenu,
            calendarWrapperRef,
            zoom: weeklyZoom,
          }}
        />
      )}
    </div>
  );
};

export default SharedCalendar;
