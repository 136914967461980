import { Alert, App } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import clsx from 'clsx';
import { AppointmentsApiService, HolidayAndUnavailabilityApiService, PatientApiService } from 'core/api';
import { IPatientDao, IUserDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  AppointmentLocation,
  AppointmentLocationData,
  AppointmentLocationOptions,
} from 'core/constants/appointment-location';
import { ControlType } from 'core/enums/control-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { and, FirestoreError, or, orderBy, where } from 'firebase/firestore';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import {
  getAppointment15MinuteTimeSlots,
  getAppointment60MinuteTimeSlots,
  getDayJsFromDateAndTimeString,
  getTimestampFromDateAndTimeString,
} from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { useDialog } from 'core/providers/dialog-provider';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';
import ProgressBar from 'shared/progress-bar/progress-bar';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';

interface IAddEditAppointmentFormOutput {
  type: string;
  clinic: string;
  location: AppointmentLocation;
  assignee: string;
  date: Dayjs;
  startTime: string;
  endTime: string;
  additionalNote?: string;
  sendConfirmation?: boolean;
}

const AddEditAppointment = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dialog = useDialog();
  const { message } = App.useApp();
  const { userData, organisationData } = useUserState();
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedAssigneeData, setSelectedAssigneeData] = useState<IDomainCalendarResource>();
  const [recommendedResources, setRecommendedResources] = useState<IDomainCalendarResource[]>([]);
  const [patientData, setPatientData] = useState<IPatientDao>();
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [appointment, setAppointment] = useState<IAppointmentDao>();
  const [loadingCalendarAppointments, setLoadingCalendarAppointments] = useState(true);
  const [unavailability, setUnavailability] = useState<IHolidayAndUnavailabilityDao[]>([]);
  const [loadingCalendarUnavailability, setLoadingCalendarUnavailability] = useState(true);
  const [mobileShowCalendar, setMobileShowCalendar] = useState(false);
  const [showSendConfirmationSwitch, setShowSendConfirmationSwitch] = useState(true);
  const selectedType = useWatch('type', form);
  const startTime = useWatch('startTime', form);
  const endTime = useWatch('endTime', form);
  const date = useWatch('date', form);
  const [startDate, setStartDate] = useState<Date>();
  const clinic = useWatch('clinic', form);
  const assignee = useWatch('assignee', form);
  const location = useWatch('location', form);
  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const patientUid = searchParams.get('patient');
  const appointmentUid = searchParams.get('appointment');
  const calendarResources = calendarResourcesState?.data;
  const today = dayjs().startOf('day');
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const startHour = calendarStart.hour();
  const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());
  const endHour = calendarEnd.hour();
  const allTimeOptions = getAppointment15MinuteTimeSlots(calendarStart, calendarEnd).map((slot) => ({
    value: slot,
    label: slot,
  }));
  const startTimeOptions = allTimeOptions.filter(({ value }) => !(endTime && value >= endTime));
  const endTimeOptions = allTimeOptions.filter(({ value }) => !(startTime && value <= startTime));
  const assigneeList = useMemo(
    () =>
      calendarResources?.filter(
        (resource) => resource.assignableAppointmentTypes.includes(selectedType) && resource.clinics.includes(clinic)
      ) || [],
    [calendarResources, clinic, selectedType]
  );

  useMemo(() => {
    if (date) {
      const newStartDate = dayjs(date).startOf('week').startOf('day').toDate();

      if (!startDate || (startDate && newStartDate.getTime() !== startDate.getTime())) {
        setStartDate(newStartDate);
      }
    }
  }, [date, startDate]);

  const fetchPatientData = useCallback(
    async (uid: string) => {
      try {
        const snap = await PatientApiService.get(uid);
        if (snap.exists()) {
          setPatientData(snap.data());
        } else {
          message.error(t('calendar.add_edit_appointment.patient_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch patient: ${uid} but they don't exist`),
            'Fetching patient data for appointment'
          );
          navigate(-1);
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_patient_error'));
        sentryCaptureException(error, 'Fetching patient data for appointment');
        navigate(-1);
      }
    },
    [message, navigate, t]
  );

  useEffect(() => {
    if (!patientUid || patientUid === 'undefined') {
      navigate(-1);
      message.info(t('calendar.add_edit_appointment.patient_not_selected'));
    } else {
      fetchPatientData(patientUid);
    }
  }, [patientUid, fetchPatientData, message, navigate, t]);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      setLoading(true);
      try {
        const snap = await AppointmentsApiService.get(appointmentUid!);
        if (snap.exists()) {
          setAppointment(snap.data());
        } else {
          message.error(t('calendar.add_edit_appointment.appointment_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch appointment: ${appointmentUid} but it doesn't exist`),
            'Fetching appointment data for editing appointment',
            userData
          );
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_appointment_error'));
        sentryCaptureException(error, 'Fetching appointment data for editing appointment');
      } finally {
        setLoading(false);
      }
    };

    if (appointmentUid) {
      fetchAppointmentData();
    } else {
      setLoading(false);
    }
  }, [appointmentUid, message, userData, t]);

  const getRecommendedResources = useCallback(async (resources: IDomainCalendarResource[], address?: IAddressDao) => {
    if (!address) {
      setRecommendedResources([]);
    } else {
      const recommended = resources.filter((resource) => {
        if (!resource.workingAreas) {
          return false;
        }
        const polys = resource.workingAreas
          .map((areaString) =>
            areaString.split('##').map((point) => {
              const { lat, lng } = JSON.parse(point) as google.maps.LatLngLiteral;
              return [lng, lat];
            })
          )
          .map((positions) => {
            const first = positions[0];
            return turf.polygon([[...positions, first]]);
          });

        return polys.some((poly) => turf.booleanPointInPolygon([address.lng, address.lat], poly));
      });
      setRecommendedResources(recommended);
    }
  }, []);

  useEffect(() => {
    if (clinic && location) {
      let address = patientData?.address;
      if (location === AppointmentLocation.CLINIC) {
        const clinicData = clinicsState?.data.find((clinicData) => clinicData.uid === clinic);
        address = clinicData?.address;
      }
      getRecommendedResources(assigneeList, address);
    }
  }, [assigneeList, clinic, clinicsState?.data, getRecommendedResources, location, patientData?.address]);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError, userData?: IUserDao) => {
      message.error(t('appointments.appointments_calendar.get_appointments_error'));
      sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
    },
    [message, t]
  );

  useEffect(() => {
    const subscriptions: Unsubscribe[] = [];
    let unsubscribe: Unsubscribe;
    if (assigneeList.length > 0 && startDate) {
      setLoadingCalendarAppointments(true);
      setLoadingCalendarUnavailability(true);
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where(
          'assignee.uid',
          'in',
          assigneeList.map((resource) => resource.uid)
        ),
      ];
      subscriptions.push(
        AppointmentsApiService.onCollectionSnapshot(
          (snap) => {
            setAppointments(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarAppointments(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [
            ...baseConstraints,
            where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
            where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
          ]
        ),
        HolidayAndUnavailabilityApiService.onCollectionSnapshot(
          (snap) => {
            setUnavailability(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarUnavailability(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [],
          {
            filter: and(
              ...baseConstraints,
              or(
                and(
                  where('repeat.isRepeating', '==', false),
                  or(
                    and(
                      where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    ),
                    and(
                      where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('endDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    )
                  )
                ),
                and(
                  where('repeat.isRepeating', '==', true),
                  where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
                  where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate())
                )
              )
            ),
            orderBy: orderBy('startDateTime'),
          }
        )
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, t, userData, startDate, assigneeList, handleSubscriptionError]);

  // useEffect(() => {
  //   form.setFieldValue('assignee', undefined);
  // }, [form, selectedType, clinic]);

  useEffect(() => {
    if (assignee) {
      setSelectedAssigneeData(calendarResources?.find((resource) => resource.uid === assignee));
    }
  }, [assignee, calendarResources]);

  useEffect(() => {
    if (startTime && !endTime) {
      const appointmentTypeFullDetail = appointmentTypeState?.data.find((type) => type.uid === selectedType);
      const start = getDayJsFromDateAndTimeString(date, startTime);
      const newEndTime = start.add(appointmentTypeFullDetail?.duration ?? 15, 'minute').format('HH:mm');
      form.setFieldValue('endTime', newEndTime);
    }
  }, [appointmentTypeState?.data, date, endTime, form, selectedType, startTime]);

  useEffect(() => {
    const appointmentTypeFullDetail = appointmentTypeState?.data.find((type) => type.uid === selectedType);
    const confirmationsEnabled =
      appointmentTypeFullDetail?.confirmation.sms.enabled ||
      appointmentTypeFullDetail?.confirmation.email.enabled ||
      false;
    setShowSendConfirmationSwitch(confirmationsEnabled);
    form.setFieldValue('sendConfirmation', confirmationsEnabled);
  }, [appointmentTypeState?.data, form, selectedType]);

  const formFields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.appointment_type'),
      options: appointmentTypeState?.data.map((type) => ({ label: type.name, value: type.uid })) ?? [],
      required: true,
    },
    {
      fieldKey: 'clinic',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.clinic'),
      options: clinicsState?.data.map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? [],
      required: true,
    },
    {
      fieldKey: 'location',
      control: ControlType.RadioButton,
      label: t('calendar.add_edit_appointment.form.location'),
      options: AppointmentLocationOptions.map((location) => {
        const option = AppointmentLocationData[location];
        return {
          label: t(option.translationLabelKey),
          value: option.value,
        };
      }),
      required: true,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'assignee',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.assignee'),
      options: assigneeList.map((resource) => ({ label: resource.fullName, value: resource.uid })) ?? [],
      required: true,
      hidden: !selectedType || !clinic,
      extra: recommendedResources.length > 0 && (
        <div className='border border-blue-400 bg-blue-50 rounded-md px-4 py-2 body-xs text-blue-950'>
          <p className='font-bold mb-1'>Recommended assignee</p>
          {recommendedResources.map((res) => (
            <p key={res.uid}>• {res.fullName}</p>
          ))}
        </div>
      ),
    },
    {
      fieldKey: 'date',
      control: ControlType.DatePicker,
      label: t('calendar.add_edit_appointment.form.date'),
      required: true,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'startTime',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.start_time'),
      options: startTimeOptions,
      required: true,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'endTime',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.end_time'),
      options: endTimeOptions,
      required: true,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'additionalNote',
      control: ControlType.TextArea,
      label: t('calendar.add_edit_appointment.form.additional_information'),
      rows: 3,
      required: false,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'sendConfirmation',
      control: ControlType.Switch,
      label: t('calendar.add_edit_appointment.form.send_confirmation'),
      required: true,
      hidden: !selectedType || !clinic || !showSendConfirmationSwitch,
    },
  ];

  const submit = async (data: IAddEditAppointmentFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);

      const createPayload = {
        uid: uuidv4(),
        organisationUid: userData?.organisationUid,
        created: userTimestamp,
      };

      const payload = {
        updated: userTimestamp,
        type: data.type,
        clinic: data.clinic,
        location: data.location,
        assignee: {
          fullName: selectedAssigneeData?.fullName ?? '',
          uid: data.assignee,
        },
        startDateTime: getTimestampFromDateAndTimeString(data.date, data.startTime),
        endDateTime: getTimestampFromDateAndTimeString(data.date, data.endTime),
        patient: patientData!,
        cancelled: false,
        outcomeHistory: [],
        confirmed: false,
        ...(data.additionalNote && { additionalNote: data.additionalNote }),
        sendConfirmation: data.sendConfirmation ?? false,
      };

      appointmentUid
        ? await AppointmentsApiService.update(appointmentUid, payload)
        : await AppointmentsApiService.set({
            ...createPayload,
            ...payload,
          });
      message.success(
        t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.success' : 'calendar.add_edit_appointment.create.success'
        )
      );
      navigate(-1);
    } catch (error) {
      message.error(
        t(appointmentUid ? 'calendar.add_edit_appointment.edit.error' : 'calendar.add_edit_appointment.create.error')
      );
      sentryCaptureException(error, appointmentUid ? 'Editing appointment' : 'Creating new appointment', userData);
      setSubmitting(false);
    }
  };

  return !loading ? (
    <>
      <SharedPageHeader
        title={t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.title' : 'calendar.add_edit_appointment.create.title'
        )}
        showBack
      />
      <div className='rounded-md bg-white shadow-md mb-4 grow flex flex-col md:flex-row overflow-hidden'>
        <div
          className={clsx(
            'w-full grow md:basis-[300px] flex flex-col justify-between md:grow-0 md:shrink-0 md:border-r overflow-y-auto',
            mobileShowCalendar && 'hidden md:block'
          )}
        >
          <SharedForm<IAddEditAppointmentFormOutput>
            formInstance={form}
            onFinish={submit}
            fields={formFields}
            submitting={submitting}
            name='add-edit-appointment-form'
            existingValue={{
              ...appointment,
              assignee: appointment?.assignee.uid,
              startTime: appointment ? dayjs(appointment.startDateTime.toDate()).format('HH:mm') : undefined,
              endTime: appointment ? dayjs(appointment.endDateTime.toDate()).format('HH:mm') : undefined,
              date: appointment ? dayjs(appointment.startDateTime.toDate()) : today,
            }}
          />
          {selectedType && date && clinic && assignee && startTime && endTime && location && selectedAssigneeData && (
            <div className='px-4 py-2 border-t'>
              <SharedButton
                labelKey='calendar.add_edit_appointment.preview_route'
                onClick={() => {
                  const newAppointmentAddress =
                    location === AppointmentLocation.HOME
                      ? patientData?.address
                      : clinicsState?.data.find((c) => c.uid === clinic)?.address;
                  if (newAppointmentAddress) {
                    dialog?.openDialog(
                      <TimelinePreviewDialog
                        appointments={appointments}
                        resource={selectedAssigneeData}
                        newAppointment={{
                          isNew: true,
                          uid: appointmentUid ?? 'new',
                          patientName: patientData!.fullName,
                          appointmentAddress: newAppointmentAddress,
                          startDateTime: getTimestampFromDateAndTimeString(date, startTime),
                          endDateTime: getTimestampFromDateAndTimeString(date, endTime),
                          clinic,
                          location,
                        }}
                      />
                    );
                  } else {
                    message.error(t('calendar.add_edit_appointment.timeline_preview.no_address'));
                  }
                }}
                appearance='link'
                fullWidth
              />
            </div>
          )}
        </div>

        <div
          className={clsx('grow flex overflow-hidden', !mobileShowCalendar && 'hidden md:flex')}
          ref={calendarWrapperRef}
        >
          {!selectedType || !date || !clinic ? (
            <div className='p-4 w-full grow'>
              <Alert
                className='self-start'
                message={t('calendar.add_edit_appointment.select_initial_fields')}
                type='info'
                showIcon
              />
            </div>
          ) : (
            <SharedCalendar
              filters={{}}
              calendarWrapperRef={calendarWrapperRef}
              startHour={startHour}
              loading={loadingCalendarAppointments || loadingCalendarUnavailability}
              timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
              appointments={appointments}
              unavailability={unavailability}
              people={assigneeList}
              currentDate={date}
              changeDate={(newDate) => form.setFieldValue('date', dayjs(newDate))}
              newAppointment={startTime && endTime ? { start: startTime, end: endTime } : undefined}
              highlightedPerson={assignee}
              highlightedClinic={clinic}
              minDate={today}
              showAppointmentMenu={false}
            />
          )}
        </div>

        <div className='p-4 border-t md:hidden'>
          <SharedButton
            onClick={() => setMobileShowCalendar(!mobileShowCalendar)}
            fullWidth
            labelKey={
              mobileShowCalendar
                ? 'calendar.add_edit_appointment.hide_calendar'
                : 'calendar.add_edit_appointment.show_calendar'
            }
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ProgressBar />
      <div className='flex py-4 h-full'>
        <SkeletonElement width='30%' height='100%' />
        <SkeletonElement width='70%' height='100%' className='ml-4' />
      </div>
    </>
  );
};

export default AddEditAppointment;
