import { useTranslation } from 'react-i18next';
import StockManagementStockTable from './stock-management-stock-table';
import SharedPageHeader from 'shared/page-header/page-header';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import { useDialog } from 'core/providers/dialog-provider';
import { where } from 'firebase/firestore';
import SharedFilter, { IFilter } from 'shared/filter/filter';
import { useEffect, useState } from 'react';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useSelector } from 'react-redux';

const StockManagementHearingAidList = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const tableKey = 'stock_management.hearing_aid_list.table';
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [manufacturersList, setManufacturersList] = useState<{ value: string; label: string }[]>([]);
  const [modelList, setModelList] = useState<{ value: string; label: string }[]>([]);

  const haringAids = useSelector(OrganisationSettingsSlice.selectHearingAids);

  useEffect(() => {
    const manufacturers = [
      ...new Set(
        haringAids?.data
          .filter(({ deleted, model }) => !deleted && (!activeFilters.model || activeFilters.model.includes(model)))
          .map(({ manufacturer }) => manufacturer)
      ),
    ].map((manufacturer) => ({ value: manufacturer, label: manufacturer }));

    setManufacturersList(manufacturers);
  }, [haringAids?.data, activeFilters]);

  useEffect(() => {
    const models = [
      ...new Set(
        haringAids?.data
          .filter(
            ({ deleted, manufacturer }) =>
              !deleted && (!activeFilters.manufacturer || activeFilters.manufacturer.includes(manufacturer))
          )
          .map(({ model }) => model)
      ),
    ].map((model) => ({ value: model, label: model }));

    setModelList(models);
  }, [haringAids?.data, activeFilters]);

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='stock_management.hearing_aid_list.header.add_hearing_aid'
            onClick={() => dialog?.openDialog(<AddEditStockDialog tableKey={tableKey} selectedType='hearingAid' />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addHearingAid',
    },
  ];

  const filters: IFilter[] = [
    {
      key: 'manufacturer',
      label: t('stock_management.hearing_aids.filters.manufacturer'),
      options: manufacturersList,
      mode: 'multiple',
    },
    {
      key: 'model',
      label: t('stock_management.hearing_aids.filters.model'),
      options: modelList,
      mode: 'multiple',
    },
  ];

  return (
    <>
      <SharedPageHeader showBack title={t('stock_management.hearing_aid_list.title')} actions={headerActions} />
      <SharedFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
      <StockManagementStockTable
        title={t('stock_management.hearing_aid_list.title')}
        tableKey={tableKey}
        additionalConstraints={[
          where('type', '==', 'hearingAid'),
          ...(activeFilters?.manufacturer?.length ? [where('manufacturer', 'in', activeFilters.manufacturer)] : []),
          ...(activeFilters?.model?.length ? [where('model', 'in', activeFilters.model)] : []),
        ]}
        additionalColumns={[
          {
            labelKey: 'stock_management.add_edit_stock.form.manufacturer',
            key: 'manufacturer',
          },
          {
            labelKey: 'stock_management.add_edit_stock.form.model',
            key: 'model',
          },
          {
            labelKey: 'stock_management.add_edit_stock.form.style',
            key: 'style',
          },
          {
            labelKey: 'stock_management.add_edit_stock.form.colour',
            key: 'colour',
          },
          {
            labelKey: 'stock_management.add_edit_stock.form.power',
            key: 'power',
          },
        ]}
      />
    </>
  );
};

export default StockManagementHearingAidList;
