import { App } from 'antd';
import clsx from 'clsx';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { and, or, orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { IStockDao } from 'core/api/types/stock.interface';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';
import dayjs from 'dayjs';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'stock_management.overview.table.header.item',
    key: 'item',
    contentTemplateId: 'item',
    width: 400,
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.serial_number',
    key: 'serialNumber',
    contentTemplateId: 'serialNumber',
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.warranty',
    key: 'warranty',
    contentTemplateId: 'warranty',
  },
  {
    labelKey: 'stock_management.add_edit_stock.form.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  {
    labelKey: 'stock_management.overview.table.header.fitted_date',
    key: 'fittedDate',
    contentTemplateId: 'fittedDate',
  },
];

const PatientProductsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [stock, setStock] = useState<IStockDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        setStock(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.products.error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(
            and(where('type', '==', 'hearingAid'), where('allocated.to.uid', '==', patient.uid)),
            and(where('type', '==', 'accessory'), where('allocation.patients', 'array-contains', patient.uid))
          )
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const itemTemplate = (stock: IStockDao) => {
    let values = [];
    switch (stock.type) {
      case 'hearingAid':
        values = [stock.manufacturer, stock.model, stock.style, stock.power, stock.colour];
        break;
      case 'accessory':
        values = [stock.manufacturer, stock.accessoryName];
        break;
    }

    return <p>{values.filter((value) => Boolean(value)).join(' / ')}</p>;
  };

  const serialTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.serialNumber && 'text-gray-400')}>
        {stock.serialNumber ?? t('common.not_provided')}
      </p>
    );
  };

  const warrantyTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.warranty && 'text-gray-400')}>
        {stock.warranty ?? t('common.not_provided')}
      </p>
    );
  };

  const statusTemplate = (stock: IStockDao) => {
    return <SharedStockStatusTableTemplate stock={stock} patientUid={patient.uid} />;
  };

  const fittedDateTemplate = (stock: IStockDao) => {
    let allocatedDate: Date | undefined;
    if (stock.type === 'hearingAid') {
      allocatedDate = stock.allocated?.at.toDate();
    } else if (stock.type === 'accessory') {
      const allocation = stock.allocation.allocations.find((a) => a.to.uid === patient.uid);
      allocatedDate = allocation?.at.toDate();
    }
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {allocatedDate ? dayjs(allocatedDate).format('DD/MM/YYYY') : t('common.unknown')}
      </p>
    );
  };

  return (
    <SharedCard title={t('patients.patient.products.title')}>
      <SharedTable
        loading={loading}
        rows={stock.map((item) => ({
          key: item.uid,
          data: item,
        }))}
        columns={tableColumns}
        contentTemplates={[
          {
            id: 'item',
            template: itemTemplate,
          },
          {
            template: serialTemplate,
            id: 'serialNumber',
          },
          {
            template: statusTemplate,
            id: 'status',
          },
          {
            template: fittedDateTemplate,
            id: 'fittedDate',
          },
          {
            template: warrantyTemplate,
            id: 'warranty',
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientProductsList;
