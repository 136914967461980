import { App, Dropdown, MenuProps } from 'antd';
import { AppointmentsApiService, HolidayAndUnavailabilityApiService } from 'core/api';
import { IUserDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { and, FirestoreError, or, orderBy, Unsubscribe, where } from 'firebase/firestore';
import AddEditHolidayUnavailabilityDialog from 'modules/organisation-settings/holidays-and-unavailability/add-edit-holiday-unavailability-dialog';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import AddEditPatientDialog from 'modules/patients/add-edit-patient-dialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import DrawerFilter from 'shared/filter/drawer-filter';
import { IFilter } from 'shared/filter/filter';
import { getAppointment60MinuteTimeSlots } from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import BulkAddAppointmentsDialog from './bulk-add-appointments-dialog';

const AppointmentsCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const calendarResources = calendarResourcesState?.data || [];
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const date = searchParams.get('date');
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [unavailability, setUnavailability] = useState<IHolidayAndUnavailabilityDao[]>([]);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const dialog = useDialog();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [clinicList, setClinicList] = useState<{ value: string; label: string }[]>([]);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date>();
  const { userPermissions } = usePermissionsState();
  const [loadingAppointments, setLoadingAppointments] = useState(true);
  const [loadingUnavailability, setLoadingUnavailability] = useState(true);
  const loading = loadingAppointments || loadingUnavailability || !date || calendarResourcesState?.status !== 'success';

  const filters: IFilter[] = [
    {
      key: 'clinic',
      label: t('calendar.filters.clinic'),
      options: clinicList,
      mode: 'multiple',
    },
    {
      key: 'assignee',
      label: t('calendar.filters.assignee'),
      options: calendarResources.map(({ uid, fullName }) => ({ value: uid, label: fullName })),
      mode: 'multiple',
    },
  ];

  const startHour = dayjs(organisationData?.calendar.startTime.toDate()).hour();
  const endHour = dayjs(organisationData?.calendar.endTime.toDate()).hour();

  useEffect(() => {
    if (!date) {
      setSearchParams((prev) => {
        prev.set('date', dayjs().format('YYYY-MM-DD'));
        return prev;
      });
    }
  }, [date, setSearchParams]);

  useEffect(() => {
    const calendarResources = calendarResourcesState?.data || [];
    const clinicsData = clinicsState?.data || [];

    const assigneeClinics = calendarResources
      .filter(({ uid }) => !activeFilters.assignee || activeFilters.assignee.includes(uid))
      .map(({ clinics }) => clinics)
      .flat();

    const clinics = clinicsData
      .filter(({ deleted }) => !deleted)
      .filter(({ uid }) => assigneeClinics.includes(uid))
      .map(({ uid, name }) => ({ value: uid, label: name }));

    setClinicList(clinics);
  }, [calendarResourcesState?.data, clinicsState?.data, activeFilters]);

  useMemo(() => {
    if (date) {
      const newStartDate = dayjs(date).startOf('week').startOf('day').toDate();

      if (!startDate || (startDate && newStartDate.getTime() !== startDate.getTime())) {
        setStartDate(newStartDate);
      }
    }
  }, [date, startDate]);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError, userData?: IUserDao) => {
      message.error(t('appointments.appointments_calendar.get_appointments_error'));
      sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
    },
    [message, t]
  );

  useEffect(() => {
    const subscriptions: Unsubscribe[] = [];
    if ((calendarResourcesState?.data?.length ?? 0) > 0 && startDate) {
      setLoadingAppointments(true);
      setLoadingUnavailability(true);
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        ...(activeFilters.assignee
          ? [where('assignee.uid', 'in', activeFilters.assignee)]
          : [
              where(
                'assignee.uid',
                'in',
                calendarResourcesState?.data.map((resource) => resource.uid)
              ),
            ]),
      ];

      subscriptions.push(
        AppointmentsApiService.onCollectionSnapshot(
          (snap) => {
            setAppointments(snap.docs.map((doc) => doc.data()));
            setLoadingAppointments(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [
            ...baseConstraints,
            where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
            where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
            ...(activeFilters.clinic ? [where('clinic', 'in', activeFilters.clinic)] : []),
          ]
        ),
        HolidayAndUnavailabilityApiService.onCollectionSnapshot(
          (snap) => {
            setUnavailability(snap.docs.map((doc) => doc.data()));
            setLoadingUnavailability(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [],
          {
            filter: and(
              ...baseConstraints,
              or(
                and(
                  where('repeat.isRepeating', '==', false),
                  or(
                    and(
                      where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    ),
                    and(
                      where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('endDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    ),
                    and(
                      where('startDateTime', '<=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('endDateTime', '>=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    )
                  )
                ),
                and(
                  where('repeat.isRepeating', '==', true),
                  where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
                  where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate())
                )
              )
            ),
            orderBy: orderBy('startDateTime'),
          }
        )
      );
    } else {
      setLoadingAppointments(false);
      setLoadingUnavailability(false);
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [
    activeFilters.assignee,
    activeFilters.clinic,
    calendarResourcesState?.data,
    calendarResourcesState?.data?.length,
    handleSubscriptionError,
    startDate,
    userData,
  ]);

  const paramChanged = (key: string, value: string) => {
    setSearchParams((prev) => {
      if (!value || value === '') {
        prev.delete(key);
      } else {
        prev.set(key, value);
      }

      return prev;
    });
  };

  const handleAddClick: MenuProps['onClick'] = async ({ key }) => {
    switch (key) {
      case 'appointment':
        dialog?.openDialog(
          <PatientSearchDialog onSelect={(patient) => navigate(`create?patient=${patient.objectID}`)} />
        );
        break;
      case 'patient':
        dialog?.openDialog(<AddEditPatientDialog navigateAfterSubmit />);
        break;
      case 'holiday':
        dialog?.openDialog(<AddEditHolidayUnavailabilityDialog tableKey='' initialDate={dayjs(date)} />);
    }
  };

  return (
    <div className='rounded-md bg-white shadow-md grow flex overflow-hidden my-4' ref={calendarWrapperRef}>
      <SharedCalendar
        filters={activeFilters}
        calendarWrapperRef={calendarWrapperRef}
        loading={loading}
        changeDate={(newDate) => paramChanged('date', newDate)}
        currentDate={dayjs(date)}
        timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
        appointments={appointments}
        unavailability={unavailability}
        people={calendarResourcesState?.data || []}
        startHour={startHour}
        extra={
          <div className='space-x-2 flex items-center'>
            <SharedElementPermissionGuard requiredPermissions={[[Permission.APPOINTMENTS_IMPORT]]}>
              <SharedButton
                onClick={() => dialog?.openDialog(<BulkAddAppointmentsDialog />)}
                type='button'
                labelKey='common.import'
              />
            </SharedElementPermissionGuard>
            <DrawerFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
            <Dropdown
              menu={{
                items: [
                  {
                    label: t('appointments.appointments_calendar.new.appointment'),
                    key: 'appointment',
                    requiredPermission: Permission.APPOINTMENTS_CREATE,
                  },
                  {
                    label: t('appointments.appointments_calendar.new.patient'),
                    key: 'patient',
                    requiredPermission: Permission.PATIENTS_CREATE,
                  },
                  {
                    label: t('appointments.appointments_calendar.new.holiday'),
                    key: 'holiday',
                    requiredPermission: Permission.HOLIDAYS_CREATE,
                  },
                ]
                  .filter(
                    ({ requiredPermission }) =>
                      userPermissions?.includes(Permission.ORGANISATION_OWNER) ||
                      userPermissions?.includes(requiredPermission)
                  )
                  .map(({ label, key }) => ({ label, key })),
                onClick: handleAddClick,
              }}
              trigger={['click']}
            >
              <div className='flex items-center'>
                <SharedButton appearance='primary' icon={<Plus size={22} />} />
              </div>
            </Dropdown>
          </div>
        }
      />
    </div>
  );
};

export default AppointmentsCalendar;
