import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { Timestamp } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import SharedCalendarWeeklyAppointment from './calendar-weekly-appointment';
import dayjs, { Dayjs } from 'dayjs';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import SharedCalendarWeeklyUnavailability from './calendar-weekly-unavailability';

interface ISharedCalendarWeeklyRow {
  existingAppointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  timeSlots: string[];
  daysOfWeek: Dayjs[];
  minimumHeight: number;
  width: number;
  showAppointmentMenu?: boolean;
}

const SharedCalendarWeeklyRow = ({
  timeSlots,
  existingAppointments,
  unavailability,
  daysOfWeek,
  minimumHeight,
  width,
  showAppointmentMenu = true,
}: ISharedCalendarWeeklyRow) => {
  const [appGroups, setAppGroups] = useState<IAppointmentDao[][][]>([]);

  const collides = useCallback((a: IAppointmentDao, b: IAppointmentDao) => {
    return a.endDateTime > b.startDateTime && a.startDateTime < b.endDateTime;
  }, []);

  useEffect(() => {
    let g: IAppointmentDao[][][] = [];
    let columns: IAppointmentDao[][] = [];
    let lastAppointmentEnding: Timestamp | undefined;
    existingAppointments
      .sort(
        (a, b) => a.startDateTime.seconds - b.startDateTime.seconds || a.endDateTime.seconds - b.endDateTime.seconds
      )
      .forEach((appointment) => {
        // Check if a new appointment group needs to be started.
        if (lastAppointmentEnding && appointment.startDateTime >= lastAppointmentEnding) {
          // The appointment is later than any of the appointments in the
          // current group. There is no overlap. Output the
          // current appointments group and start a new one.
          g.push(columns);
          columns = [];
          lastAppointmentEnding = undefined;
        }

        // Try to place the appointment inside an existing column.
        let placed = false;
        columns.some((col) => {
          if (!collides(col[col.length - 1], appointment)) {
            col.push(appointment);
            placed = true;
          }
          return placed;
        });

        // It was not possible to place the appointment (it overlaps
        // with apps in each existing column). Add a new column
        // to the current appointment group with the appointment in it.
        if (!placed) columns.push([appointment]);

        // Remember the last appointment end time of the current group.
        if (!lastAppointmentEnding || appointment.endDateTime > lastAppointmentEnding) {
          lastAppointmentEnding = appointment.endDateTime;
        }
      });
    g.push(columns);
    setAppGroups(g);
  }, [collides, existingAppointments]);

  const expand = (a: IAppointmentDao, index: number, cols: IAppointmentDao[][]) => {
    let colSpan = 1;
    cols.slice(index + 1).some((col) => {
      if (col.some((app) => collides(a, app))) return true;
      colSpan += 1;
      return false;
    });
    return colSpan;
  };

  return (
    <div className='relative flex grow h-full'>
      {daysOfWeek.map((day) => (
        <div
          className={`relative w-full flex grow border-r last:border-r-0`}
          key={day.format('YYYY-MM-DD')}
          style={{ minHeight: `${minimumHeight}px`, minWidth: `${width}px` }}
        >
          {appGroups.map((cols) =>
            cols.map((appointments, index) =>
              appointments
                .filter((appointment) => dayjs(appointment.startDateTime.toDate()).isSame(day, 'day'))
                .map((appointment) => (
                  <SharedCalendarWeeklyAppointment
                    key={appointment.uid}
                    appointment={appointment}
                    width={width / timeSlots.length}
                    heightPercent={expand(appointment, index, cols) / cols.length}
                    topPercent={index / cols.length}
                    showAppointmentMenu={showAppointmentMenu}
                  />
                ))
            )
          )}
          {unavailability
            .filter((u) => dayjs(u.startDateTime.toDate()).isSame(day, 'day'))
            .map((u) => (
              <SharedCalendarWeeklyUnavailability key={u.uid} unavailability={u} width={width / timeSlots.length}/>
            ))}
          {timeSlots.map((slot) => (
            <div key={slot} className={`w-full flex grow h-full border-r border-b last:border-r-0 overflow-hidden`} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SharedCalendarWeeklyRow;
