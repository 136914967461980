import { App } from 'antd';
import { StockApiService } from 'core/api';
import { IPatientSearchResult } from 'core/api/types';
import { IStockDao } from 'core/api/types/stock.interface';
import { StockStatus } from 'core/constants/stock-status';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { arrayUnion, increment, Timestamp } from 'firebase/firestore';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IStockAllocationDialog {
  patient: IPatientSearchResult;
  status: StockStatus;
  stock: IStockDao[];
  tableKey: string;
  setSelectedStock: Dispatch<SetStateAction<IStockDao[]>>;
}

interface IStockAllocationDialogFormOutput {
  allocatedDate: Dayjs;
}

const StockAllocationDialog = ({ patient, status, stock, tableKey, setSelectedStock }: IStockAllocationDialog) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();
  const table = useTable(tableKey);

  const onSubmit = async (data: IStockAllocationDialogFormOutput) => {
    try {
      message.success(t('stock_management.overview.table.allocated.success'));
      const promises = stock.map((stockItem) => {
        if (stockItem.type === 'accessory') {
          return StockApiService.update(stockItem.uid, {
            updated: getActionTimestampFromUser(userData),
            quantity: increment(-1),
            'allocation.allocations': arrayUnion({
              at: Timestamp.fromDate(data.allocatedDate.toDate()),
              to: {
                uid: patient.objectID,
                fullName: patient.fullName,
              },
              status,
            }),
            'allocation.patients': arrayUnion(patient.objectID),
          });
        } else {
          return StockApiService.update(stockItem.uid, {
            updated: getActionTimestampFromUser(userData),
            status,
            allocated: {
              at: Timestamp.fromDate(data.allocatedDate.toDate()),
              to: {
                uid: patient.objectID,
                fullName: patient.fullName,
              },
            },
          });
        }
      });
      await Promise.all(promises);
      dialog?.closeDialog();
      setSelectedStock([]);
      table.refreshTable();
    } catch (error) {
      setLoading(false);
      sentryCaptureException(error, 'Allocating stock', userData);
      message.error(t('stock_management.overview.table.allocated.error'));
    }
  };

  const content = (
    <SharedForm<IStockAllocationDialogFormOutput>
      name='stock-allocation-form'
      onFinish={onSubmit}
      submitting={loading}
      cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
      fields={[
        {
          fieldKey: 'allocatedDate',
          control: ControlType.DatePicker,
          label: t('stock_management.stock_allocation.form.allocated_date'),
          required: true,
          fullWidth: true,
        },
      ]}
      existingValue={{
        allocatedDate: dayjs(),
      }}
    />
  );

  return (
    <SharedDialogBase
      title={t('stock_management.stock_allocation.title')}
      customContentTemplate={content}
      showButtons={false}
    />
  );
};

export default StockAllocationDialog;
