import clsx from 'clsx';
import { IStockDao } from 'core/api/types/stock.interface';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { StockStatus } from 'core/constants/stock-status';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { QueryConstraint, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { StockApiService } from 'core/api';
import { useState } from 'react';
import { Checkbox, Input, Popover } from 'antd';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';
import StockAllocationDialog from './stock-allocation-dialog';
import { IPatientSearchResult } from 'core/api/types';
import { Link } from 'react-router-dom';
import { useTheme } from 'core/providers/theme-provider';

interface IStockManagementStockTable {
  additionalColumns?: ISharedTableColumn[];
  additionalContentTemplates?: ISharedTableCustomTemplate[];
  tableKey: string;
  additionalConstraints?: QueryConstraint[];
  title?: string;
  showSerialNumberLookup?: boolean;
}

const StockManagementStockTable = ({
  additionalColumns = [],
  additionalContentTemplates = [],
  tableKey,
  additionalConstraints = [],
  title,
  showSerialNumberLookup = false,
}: IStockManagementStockTable) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const dialog = useDialog();
  const [selectedStock, setSelectedStock] = useState<IStockDao[]>([]);
  const { primary } = useTheme();
  const [searchTerm, setSearchTerm] = useState<string>();

  const selectTemplate = (stock: IStockDao) => {
    return (
      <Checkbox
        disabled={stock.type === 'accessory' && stock.quantity === 0}
        checked={selectedStock.some((selected) => selected.uid === stock.uid)}
        onChange={() => {
          const index = selectedStock.findIndex((selected) => selected.uid === stock.uid);
          if (index > -1) {
            setSelectedStock((prevState) => prevState.toSpliced(index, 1));
          } else {
            setSelectedStock([...selectedStock, stock]);
          }
        }}
      />
    );
  };

  const serialTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('body-sm', !stock.serialNumber && 'text-gray-400')}>
        {stock.serialNumber ?? t('common.not_provided')}
      </p>
    );
  };

  const statusTemplate = (stock: IStockDao) => {
    return <SharedStockStatusTableTemplate stock={stock} tableKey={tableKey} />;
  };

  const locationTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('body-sm', !stock.location && 'text-gray-400')}>
        {stock.location ?? t('common.not_provided')}
      </p>
    );
  };
  const warrantyTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('body-sm', !stock.warranty && 'text-gray-400')}>
        {stock.warranty ?? t('common.not_provided')}
      </p>
    );
  };

  const allocationTemplate = (stock: IStockDao) => {
    if (stock.type === 'accessory' && stock.allocation.patients.length > 0) {
      return (
        <Popover
          placement='topLeft'
          content={
            <div className='flex flex-col max-h-[500px] overflow-y-auto'>
              {stock.allocation.allocations.map((a) => (
                <Link
                  className='border-b last:border-b-0 body-sm py-2 last:pb-0 first:pt-0'
                  key={a.to.uid}
                  to={`/patients/${a.to.uid}/products`}
                  style={{ color: primary.bg }}
                >
                  {a.to.fullName}
                </Link>
              ))}
            </div>
          }
          trigger='click'
        >
          <p className='cursor-pointer' style={{ color: primary.bg }}>
            {t('stock_management.overview.table.allocation.accessory', {
              count: stock.allocation.patients.length,
            })}
          </p>
        </Popover>
      );
    } else if (stock.type === 'hearingAid' && stock.allocated) {
      return (
        <Link to={`/patients/${stock.allocated.to.uid}/products`} style={{ color: primary.bg }}>
          <p className='body-sm whitespace-pre-wrap'>{stock.allocated.to.fullName}</p>
        </Link>
      );
    } else {
      return (
        <p className='body-sm whitespace-pre-wrap text-gray-400'>
          {t('stock_management.overview.table.allocation.unallocated')}
        </p>
      );
    }
  };

  const actionTemplate = (stock: IStockDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(<AddEditStockDialog selectedType={stock.type} stock={stock} tableKey={tableKey} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => StockApiService.permDelete(stock.uid)}
                  tableRefreshKeys={[tableKey, 'stockManagementCounts']}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('stock_management.overview.delete_stock_dialog.title')}
                  textContent={t('stock_management.overview.delete_stock_dialog.content')}
                  successMessage={t('stock_management.overview.delete_stock_dialog.success')}
                  errorMessage={t('stock_management.overview.delete_stock_dialog.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const handleStockAllocation = async (
    patient: IPatientSearchResult,
    status: StockStatus.ON_TRIAL | StockStatus.SOLD
  ) => {
    dialog?.replaceDialog(
      <StockAllocationDialog
        patient={patient}
        status={status}
        stock={selectedStock}
        tableKey={tableKey}
        setSelectedStock={setSelectedStock}
      />
    );
  };

  return (
    <SharedCard
      title={title}
      extra={
        <div className='space-x-2 flex'>
          {selectedStock.length > 0 && (
            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.STOCK_UPDATE]]}
            >
              <SharedButton
                labelKey='stock_management.overview.table.on_trial'
                onClick={() =>
                  dialog?.openDialog(
                    <PatientSearchDialog onSelect={(result) => handleStockAllocation(result, StockStatus.ON_TRIAL)} />
                  )
                }
              />
              <SharedButton
                labelKey='stock_management.overview.table.sold'
                onClick={() =>
                  dialog?.openDialog(
                    <PatientSearchDialog onSelect={(result) => handleStockAllocation(result, StockStatus.SOLD)} />
                  )
                }
              />
            </SharedElementPermissionGuard>
          )}
          {showSerialNumberLookup && <SerialNumberLookup searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}
        </div>
      }
    >
      {userData && (
        <SharedPaginatedTable
          collectionId={CollectionID.STOCK}
          queryConstraints={[
            where('organisationUid', '==', userData?.organisationUid),
            searchTerm ? where('serialNumber', '==', searchTerm) : where('status', '!=', StockStatus.SOLD),
            ...additionalConstraints,
          ]}
          queryOrder={['updated.at', 'desc']}
          tableConfig={{
            headerBackgroundColor: '#f8fafc',
            columns: [
              {
                key: 'select',
                contentTemplateId: 'select',
                width: 28,
              },
              ...additionalColumns,
              {
                labelKey: 'stock_management.add_edit_stock.form.serial_number',
                key: 'serialNumber',
                contentTemplateId: 'serialNumber',
                width: 200,
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.warranty',
                key: 'warranty',
                width: 200,
                contentTemplateId: 'warranty',
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.location',
                key: 'location',
                contentTemplateId: 'location',
                width: 180,
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.status',
                key: 'status',
                contentTemplateId: 'status',
                width: 120,
              },
              {
                labelKey: 'stock_management.overview.table.header.allocation',
                key: 'allocation',
                contentTemplateId: 'allocation',
              },
              {
                labelKey: 'common.updated',
                key: 'lastUpdated',
                contentTemplateId: 'lastUpdated',
              },
              { key: 'action', contentTemplateId: 'actions', width: 96 },
            ],
            contentTemplates: [
              {
                template: selectTemplate,
                id: 'select',
              },
              ...additionalContentTemplates,
              { template: locationTemplate, id: 'location' },
              {
                template: serialTemplate,
                id: 'serialNumber',
              },
              {
                template: statusTemplate,
                id: 'status',
              },
              {
                template: allocationTemplate,
                id: 'allocation',
              },
              {
                template: warrantyTemplate,
                id: 'warranty',
              },
              {
                template: actionTemplate,
                id: 'actions',
              },
            ],
          }}
          errorMessageKey='stock_management.overview.table.error'
          tableKey={tableKey}
        />
      )}
    </SharedCard>
  );
};

export default StockManagementStockTable;

interface ISerialNumberLookup {
  searchTerm: string | undefined;
  setSearchTerm: (value: string | undefined) => void;
}

const SerialNumberLookup = ({ searchTerm, setSearchTerm }: ISerialNumberLookup) => {
  const [term, setTerm] = useState<string>();
  const { t } = useTranslation();
  return (
    <div className='flex space-x-2'>
      <Input
        disabled={!!searchTerm}
        value={term}
        placeholder={t('stock_management.overview.table.search.placeholder')}
        onChange={(event) => {
          setTerm(event.target.value);
          if (searchTerm) {
            setSearchTerm(undefined);
          }
        }}
      />
      <SharedButton
        labelKey={searchTerm ? 'common.cancel' : 'common.search'}
        disabled={!term}
        onClick={() => {
          setSearchTerm(searchTerm ? undefined : term);
        }}
      />
    </div>
  );
};
