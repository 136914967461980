import { INavbarOption } from 'core/config/navbar-options';
import { Permission } from 'core/constants/permission';

export const getPatientNavOptions = (uid: string): INavbarOption[] => [
  {
    labelKey: 'navigation.patient.overview',
    route: `patients/${uid}/overview`,
    requiredPermissions: [[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.appointments',
    route: `patients/${uid}/appointments`,
    requiredPermissions: [
      [Permission.APPOINTMENTS_READ_ALL],
      [Permission.APPOINTMENTS_READ_MY_CALENDAR],
      [Permission.APPOINTMENTS_READ_MY_CLINICS],
      [Permission.ORGANISATION_OWNER],
    ],
  },
  {
    labelKey: 'navigation.patient.hearing_test_results',
    route: `patients/${uid}/hearing-tests`,
    requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.orders',
    route: `patients/${uid}/orders`,
    requiredPermissions: [[Permission.ORDERS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.products',
    route: `patients/${uid}/products`,
    requiredPermissions: [[Permission.STOCK_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.transactions',
    route: `patients/${uid}/transactions`,
    requiredPermissions: [[Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.patient.documents',
    route: `patients/${uid}/documents`,
    requiredPermissions: [[Permission.PATIENT_DOCUMENTS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.patient.forms',
    route: `patients/${uid}/forms`,
    requiredPermissions: [[Permission.PATIENT_FORMS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.patient.notes',
    route: `patients/${uid}/notes`,
    requiredPermissions: [[Permission.PATIENT_NOTES_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.patient.communications',
    route: `patients/${uid}/communications`,
    requiredPermissions: [[Permission.COMMUNICATIONS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.patient.alerts',
    route: `patients/${uid}/alerts`,
    requiredPermissions: [[Permission.PATIENT_ALERTS_READ], [Permission.ORGANISATION_OWNER]],
  },
  {
    labelKey: 'navigation.audit_log',
    route: `patients/${uid}/audit-log`,
    requiredPermissions: [[Permission.AUDIT_LOG_READ], [Permission.ORGANISATION_OWNER]],
  },
];
