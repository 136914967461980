import { Form } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface ISharedSignatureField {
  id?: string;
  onChange?: (value?: SignatureCanvas | null) => void;
  disabled?: boolean;
  dataUrl?: string;
}

const SharedSignatureField = ({ id, onChange, disabled, dataUrl }: ISharedSignatureField) => {
  const { status } = Form.Item.useStatus();
  const [signature, setSignature] = useState<SignatureCanvas | null>(null);

  useEffect(() => {
    if (signature) {
      onChange?.(signature);
      if (disabled) {
        signature.off();
      } else {
        signature.on();
      }
      if (dataUrl) {
        signature.fromDataURL(dataUrl);
      }
    }
  }, [dataUrl, disabled, onChange, signature]);

  return (
    <>
      <SignatureCanvas
        penColor='black'
        canvasProps={{
          className: clsx(
            'border w-full h-[200px]',
            status === 'error' && 'border-red-500',
            disabled && 'cursor-not-allowed bg-black/[0.04]'
          ),
        }}
        ref={(ref) => setSignature(ref)}
      />
    </>
  );
};

export default SharedSignatureField;
